import axios from 'axios';
import * as config from '../config';

export const SETISLOADINGFLOORS = 'floors/SETISLOADINGFLOORS';
export const SETFLOORS = 'floors/SETFLOORS';

export const getFloors = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISLOADINGFLOORS, isLoadingFloors: true});

    axios(`${config.endpoints.base_url}floorplans`).then((resp) => {
      dispatch({type: SETFLOORS, floors: transformFloors(resp.data.data.rows)});
      dispatch({type: SETISLOADINGFLOORS, isLoadingFloors: false});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}

const transformFloors = (floors) => {
  return floors.map(floor => {
    return {
      ...floor
    }
  })
};

export const getFloorplanDestinations = (floorplanID) => {
  return axios.get(
    `${config.endpoints.base_url}floorplans/${floorplanID}/destinations`
  );
};

export const getSiteSensors = (siteID) => {
  return axios.get(
    `${config.endpoints.base_url}v1/sites/${siteID}/feeders`
  );
};