import axios from 'axios';
import * as config from '../config';

export const SETISLOADINGLOCATIONS = 'locations/SETISLOADINGLOCATIONS';
export const SETISLOADINGLOCATIONSWITHDATA = 'locations/SETISLOADINGLOCATIONS';
export const SETLOCATIONS = 'locations/SETLOCATIONS';
export const SETLOCATIONSWITHDATA = 'locations/SETLOCATIONSWITHDATA';

export const getLocations = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISLOADINGLOCATIONS, isLoadingLocations: true});

    axios(`${config.endpoints.base_url}locations`).then((resp) => {
      dispatch({type: SETISLOADINGLOCATIONS, isLoadingLocations: false});
      dispatch({type: SETLOCATIONS, locations: transformLocations(resp.data.data.rows, getState)});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}

export const getLocationsByServiceApplet = (serviceapplet = '', onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISLOADINGLOCATIONSWITHDATA, isLoadingLocations: true});

    axios(`${config.endpoints.base_url}locations${serviceapplet ? `?usecase=${serviceapplet}` : ''}`).then((resp) => {
      dispatch({type: SETISLOADINGLOCATIONSWITHDATA, isLoadingLocationsWithData: false});
      dispatch({type: SETLOCATIONSWITHDATA, locationsWithData: transformLocations(resp.data.data.rows, getState)});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}

const transformLocations = (locations, getState) => {

  // const staffs = getState().scenes.staffs;

  return locations.map(location => {
    // const staff = staffs.find(staff => location.assigned_to === staff.id)
    const tags = location.tags.map((tag) => {
      return { 
        ...tag,
        value: tag.id,
        label: tag.name,
      }
    });
    return {
      ...location,
      // assigned_to: {value: location.assigned_to, label: staff?.first_name},
      tags
    }
  })
}

export const getLocationByID = (location_id) => {
  return (dispatch, getState) => {
    if(getState().locations.locations) {
      const location = getState().locations.locations.find((location) => {
        return location.id === parseInt(location_id, 10);
      });
      if(location) {
        return location;
      }
    }
    return null;
  };
}

// Load location with currently loaded service applet data
export const getLocationByIDWithData = (location_id) => {
  return (dispatch, getState) => {
    if(getState().locations.locationsWithData) {
      const location = getState().locations.locationsWithData.find((location) => {
        return location.id === parseInt(location_id, 10);
      });
      if(location) {
        return location;
      }
    }
    return null;
  };
}

export const getLocationsByNetworkID = (network_id) => {
  return (dispatch, getState) => {
  };
}


export const getLocationsBySiteID = (site_id) => {
  return (dispatch, getState) => {
    if(getState().locations.locations) {
      const locations = getState().locations.locations.filter((location) => {
        return location.site_id === parseInt(site_id, 10);
      });
      if(locations) {
        return locations;
      }
    }
    return null;
  };
}

export const SETISADDINGLOCATION = 'sites/SETISADDINGLOCATION';

export const addLocation = ({ zone, zoneFile }, onSuccess = () => {}, onError = () => {}) => {
  return async(dispatch, getState) => {

    dispatch({type: SETISADDINGLOCATION, isAddingLocation: true});

    if(zoneFile) {
      const formData = new FormData();

      formData.append('files', zoneFile);
      const fileRequest = await axios.post(
        `${config.endpoints.base_url}assets`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );

      zone.asset = fileRequest.data.data[0].url;
    }

    const retransformedLocation = retransformLocation(zone);

    axios.post(
      `${config.endpoints.base_url}locations`,
      retransformedLocation
    ).then((resp) => {
      dispatch({type: SETISADDINGLOCATION, isAddingLocation: false});
      onSuccess(resp);
    }).catch((err) => {
      onError(err);
    })
  }
}

export const addLocationRequest = ({ zone }) => {
  const retransformedLocation = retransformLocation(zone);
  return axios.post(
    `${config.endpoints.base_url}locations`,
    retransformedLocation
  );
};

export const SETISEDITINGLOCATION = 'sites/SETISEDITINGLOCATION';

export const editLocation = ({ zoneID, zone, zoneFile }, onSuccess = () => {}, onError = () => {}) => {
  return async(dispatch, getState) => {

    dispatch({type: SETISEDITINGLOCATION, isEditingLocation: true});

    if(zoneFile) {
      const formData = new FormData();

      formData.append('files', zoneFile);
      const fileRequest = await axios.post(
        `${config.endpoints.base_url}assets`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );

      zone.asset = fileRequest.data.data[0].url;
    }

    const retransformedLocation = retransformLocation(zone);

    axios.put(
      `${config.endpoints.base_url}locations/${zoneID}`,
      retransformedLocation
    ).then((resp) => {
      dispatch({type: SETISEDITINGLOCATION, isEditingLocation: false});
      onSuccess(resp);
    }).catch((err) => {
      onError(err);
    })

  }
}

export const editLocationRequest = ({ zoneID, zone }) => {
  const retransformedLocation = retransformLocation(zone);
  return axios.put(
    `${config.endpoints.base_url}locations/${zoneID}`,
    retransformedLocation
  );
};

const retransformLocation = (location) => {

  let tags = [];
  if(location.tags?.length) {
    tags = location.tags?.map(tag => tag.value);
  }

  return {
    name: location.name,
    site_id: parseInt(location.site_id, 10),
    description: location.description,
    assigned_to: location?.assigned_to?.value ? location.assigned_to?.value : null,
    points: location?.points ? location.points : [],
    tags,
    asset: location?.asset ? location.asset : null,
    capacity: location?.capacity ? location.capacity : 0,
    floorplan_id: location?.floorplan_id ? location.floorplan_id : null,
  }
}

export const updateLocationRequestConfig = ({ zoneID, zoneConfig }) => {
  let zone = {...zoneConfig};
  delete zone.id; 

  return axios.put(
    `${config.endpoints.base_url}locations/${zoneID}`,
    zone
  );
};

export const SETISREMOVINGLOCATION = 'sites/SETISREMOVINGLOCATION';

export const removeLocation = ({ id }, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISREMOVINGLOCATION, isRemovingLocation: true});
    axios.delete(
      `${config.endpoints.base_url}locations/${id}`
    ).then((resp) => {
      dispatch({type: SETISREMOVINGLOCATION, isRemovingLocation: false});
      return onSuccess();
    }).catch((err) => {
      onError(err);
    })
  }
}

export const removeLocationRequest = (id) => {
  return axios.delete(`${config.endpoints.base_url}locations/${id}`);
};

export const SETISLOADINGDOORWAYS = 'locations/SETISLOADINGDOORWAYS';
export const SETDOORWAYS = 'locations/SETDOORWAYS';

export const getDoorways = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISLOADINGDOORWAYS, isLoadingDoorways: true});

    axios(`${config.endpoints.base_url}doorways`).then((resp) => {
      dispatch({type: SETISLOADINGDOORWAYS, isLoadingDoorways: false});
      dispatch({type: SETDOORWAYS, doorways: transformDoorways(resp.data.data.rows, getState)});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}

const transformDoorways = (doorways, getState) => {
  const staffs = getState().scenes.staffs;
  return doorways.map(doorway => {
    return {
      ...doorway
    }
  })
}

const retransformDoorway = (doorway) => {

  const sensors = doorway.feeders.map((feeder) => {
    return feeder.id;
  });

  let location_id = null;
  if(typeof doorway.location_id === 'object' && doorway.location_id  !== null) {
    location_id = doorway.location_id.id;
  } else {
    location_id = doorway.location_id;
  }

  let points;
  if(doorway?.doorwayInstance) {
    points = [doorway?.doorwayInstance?.get('left'), doorway?.doorwayInstance?.get('top')]
  } else {
    points = doorway.points;
  }

  return {
    name: doorway.name,
    description: doorway.description,
    floorplan_id: doorway.floorplan_id,
    site_id: doorway.site_id,
    sensors,
    location_id,
    points
  }
}

export const uploadLocationImageRequest = (file) => {
  const formData = new FormData();

  formData.append('files', file);
  return axios.post(
    `${config.endpoints.base_url}assets`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  );
}

export const createDoorwayRequest = (doorway) => {
  const retransformedDoorway = retransformDoorway(doorway);
  return axios.post(`${config.endpoints.base_url}doorways`, retransformedDoorway);
};

export const updateDoorwayRequest = (id, doorway) => {
  const retransformedDoorway = retransformDoorway(doorway);
  return axios.put(`${config.endpoints.base_url}doorways/${id}`, retransformedDoorway);
};

export const removeDoorwayRequest = (id) => {
  return axios.delete(`${config.endpoints.base_url}doorways/${id}`);
};