import axios from 'axios';
import * as config from '../config';

export const SETISLOADINGTAGS = 'tags/SETISLOADINGTAGS';
export const SETTAGS = 'tags/SETTAGS';

export const getTags = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch) => {

    dispatch({ type: SETISLOADINGTAGS, isLoadingLocations: true });

    axios(`${config.endpoints.base_url}tags`).then((resp) => {
      dispatch({ type: SETISLOADINGTAGS, isLoadingTags: false });
      dispatch({ type: SETTAGS, tags: resp.data.data.rows });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const addTag = (tag, onSuccess = () => {}) => {
  return () => {
    axios.post( `${config.endpoints.base_url}tags`, { name: tag }).then((resp) => {
      onSuccess(resp);
    });
  };
};

export const addTagRequest = (tag) => {
  return axios.post( `${config.endpoints.base_url}tags`, { name: tag });
};

export const MAXTAGLENGTH = 40;