import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import networks from './networks';
import auth from './auth';
import sites from './sites';
import tags from './tags';
import floors from './floors';
import locations from './locations';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  networks,
  auth,
  sites,
  tags,
  floors,
  locations
});

export default createRootReducer;
