import jwt_decode from 'jwt-decode';
import definedRoles from '../constants/roles.json';
import { head, isPlainObject } from 'lodash';
import vianaBentoIcon from '../assets/img/icon-bento-viana.png';

// CHANGE these lines if roles/permissions by network is implemented
import { intersection } from 'lodash';
import ROLES_NESPRESSO from 'src/constants/roles_nespresso.json';
import { NESPRESSONETWORKNAMES, ROLES_PERMISSIONS } from 'src/constants/roles_permissions_nespresso';

import { endpoints } from 'src/config';
import { getMyNetwork } from 'src/actions/networks';

export const getBentoItems = (network) => {
  let { networkapps } = network;
  let bentoItems = [{
    source: endpoints.viana_url, // will refresh page
    name: 'Viana',
    imageUrl: vianaBentoIcon,
  }];

  let apps = networkapps && networkapps.length > 0 ? networkapps.map(s => s.app) : [];
  if (apps.length > 0) {
    apps.filter(app => app?.config?.category === 'bento').forEach((item) => {
      bentoItems.push({
        source: item.source,
        name: item.name,
        imageUrl: item.config.logo || 'https://via.placeholder.com/150',
      })
    });
  }

  return bentoItems
}

export const getCurrentNetworkDetails = async (network) => {
  const getMyNetworkResp = await getMyNetwork()
  let currentNetworkDetails = '';
  getMyNetworkResp.data.map((network)=>{
    if(network.name == localStorage.getItem('currentNetwork')){
      currentNetworkDetails = network
    }
  });
  
  return currentNetworkDetails
}



export const getUserRole = () => {
  // Getting access token from local storage
  let userRole = '';
  const accessToken = localStorage.getItem('access_token');

  // if access token is not undefined, get the user role, else return ''.
  if (accessToken !== undefined) {
    // Decoding access token to get the data

    let auth = null
    try {
      auth = jwt_decode(accessToken);
    }
    catch (e) {
      window.location.reload();
    }
    // Destructuring decoded access token
    const { realm_access } = auth;
    // Getting roles from access token
    const { roles } = realm_access;
    // Finding user role based on the defined roles in roles.json, made the JSON into an array in order to map and then used .find to find the role of the user.
    userRole = roles.find(role => {
      return Object.values(definedRoles).map(definedRole => definedRole === role)
    })
  }
  return userRole;
};


export const getUserRolesV2 = () => { 
  let userRoles = [];
  const accessToken = localStorage.getItem('access_token');

  if (accessToken !== undefined) {
    let auth = null
    try {
      auth = jwt_decode(accessToken);
    }
    catch (e) {
      window.location.reload();
    }

    try {
      let currentNetwork = localStorage.getItem('currentNetwork');
      let resourceAccess = auth.resource_access[currentNetwork];
      if(resourceAccess != undefined){
        const { roles } = resourceAccess;
        userRoles = roles.map(r => ({ name: r }));
      }
      
    } catch (error) { console.log(error)}
  }
 
  return userRoles;
};

// formerly hasPermissions, changed due to naming and use
export const hasRole = (allowedRoles, userRoles) => {
  if (Array.isArray(userRoles)) {
    if (userRoles?.find((role) => allowedRoles.includes(role.name))) {
      return true
    }
    return false;
  }
  else {
    if (allowedRoles.indexOf(userRoles) >= 0) {
      return true
    }
    return false;
  }

}

const checkIdentity = (identity) => {
  return identity?.name == 'edgeless' ? true : false;
}

export const isEdgelessSensor = (sensor) => {

  if(isPlainObject(sensor?.identity)) {
    return checkIdentity(sensor?.identity)
  }
  else {
    if (sensor?.identity == undefined || sensor?.identity.length > 0 ) {
      let identity = head(sensor?.identity);
      return checkIdentity(identity)
    }
    else return false;
  }
}

export const getSensorName = (sensor) => {
  let _sensorName;
  if (isEdgelessSensor(sensor)) {
    _sensorName = sensor?.name || sensor?.Name;
  }
  else {
    _sensorName = sensor?.camera?.name ? sensor.camera.name : '';
  }

  return _sensorName;
}


export const getNetworkName = () => {
  const token = localStorage.getItem('access_token');

  try {
    return jwt_decode(token).networks;
  }
  catch (e) {
    window.location.reload();
  }
};

// Will get roles intended for portal use
export const getUserPortalRoles = () => {
  const userRoles = getUserRolesV2();
  if (userRoles) {
    const rolesNespresso = Object.values(ROLES_NESPRESSO);
    const userRolesValues = userRoles.map(role => role.name);

    const userPortalRoles = intersection(rolesNespresso, userRolesValues);
    return userPortalRoles;
  }
  return [];
};

// Currently this is for `nespresso` network,
// will return `true` if network name is something else
export const hasPermission = (permission) => {
  const userPortalRoles = getUserPortalRoles();
  const network = getNetworkName();

  if (userPortalRoles && network) {
    // Change this line if roles/permissions by network is implemented
    if(network.some(networkName => NESPRESSONETWORKNAMES.includes(networkName)) !== -1) {
      let hasPermissionFlag = false;

      ROLES_PERMISSIONS.forEach(rolePermission => {
        if (userPortalRoles.indexOf(rolePermission.role) !== -1) {
          if (rolePermission.permissions.indexOf(permission) !== -1) {
            hasPermissionFlag = true;
          }
        }
      });

      return hasPermissionFlag;
    }

    return true;
  }

  return true;
};
