import ROLES from './roles_nespresso.json';
import PERMISSIONS from './permissions.json';

// https://docs.google.com/spreadsheets/d/10c9CjgO7Eu_RFl3Du-5cnJlroqxGMGG2hyOlvhpKges/edit#gid=186479003

export const NESPRESSONETWORKNAMES = ['nespresso', 'nespresso_skunks'];

export const ROLES_PERMISSIONS = [
  {
    role: ROLES.SUPERADMINISTRATOR,
    permissions: [
      PERMISSIONS.USER_ACCESS_ADMINISTRATOR_ACCESS,
      PERMISSIONS.USER_ACCESS_ADD_USER,
      PERMISSIONS.USER_ACCESS_EDIT_OTHER_USER_ROLES,
      PERMISSIONS.USER_ACCESS_ASSIGN_USERS_TO_SITES,
      PERMISSIONS.USER_ACCESS_VIEW_USERS_ASSIGNED_TO_SITE,
      PERMISSIONS.USER_ACCESS_VIEW_ALL_USERS_IN_THE_NETWORK,
      PERMISSIONS.USER_ACCESS_DELETE_USER
    ]
  },
  { 
    role: ROLES.ADMINISTRATOR,
    permissions: [
      PERMISSIONS.USER_ACCESS_ADMINISTRATOR_ACCESS,
      PERMISSIONS.USER_ACCESS_ADD_USER,
      PERMISSIONS.USER_ACCESS_EDIT_OTHER_USER_ROLES,
      PERMISSIONS.USER_ACCESS_ASSIGN_USERS_TO_SITES,
      PERMISSIONS.USER_ACCESS_VIEW_USERS_ASSIGNED_TO_SITE,
      PERMISSIONS.USER_ACCESS_VIEW_ALL_USERS_IN_THE_NETWORK,
      PERMISSIONS.USER_ACCESS_DELETE_USER
    ]
  },
  { 
    role: ROLES.STOREMANAGER,
    permissions: [
      // PERMISSIONS.USER_ACCESS_ADMINISTRATOR_ACCESS,
      // PERMISSIONS.USER_ACCESS_ADD_USER,
      // PERMISSIONS.USER_ACCESS_EDIT_OTHER_USER_ROLES,
      // PERMISSIONS.USER_ACCESS_ASSIGN_USERS_TO_SITES,
      // PERMISSIONS.USER_ACCESS_VIEW_USERS_ASSIGNED_TO_SITE,
      // PERMISSIONS.USER_ACCESS_VIEW_ALL_USERS_IN_THE_NETWORK,
      // PERMISSIONS.USER_ACCESS_DELETE_USER
    ]
  },
  { 
    role: ROLES.STAFF,
    permissions: [
      // PERMISSIONS.USER_ACCESS_ADMINISTRATOR_ACCESS,
      // PERMISSIONS.USER_ACCESS_ADD_USER,
      // PERMISSIONS.USER_ACCESS_EDIT_OTHER_USER_ROLES,
      // PERMISSIONS.USER_ACCESS_ASSIGN_USERS_TO_SITES,
      // PERMISSIONS.USER_ACCESS_VIEW_USERS_ASSIGNED_TO_SITE,
      // PERMISSIONS.USER_ACCESS_VIEW_ALL_USERS_IN_THE_NETWORK,
      // PERMISSIONS.USER_ACCESS_DELETE_USER
    ]
  },
  { 
    role: ROLES.MODIFICATION,
    permissions: [
      PERMISSIONS.USER_ACCESS_ADMINISTRATOR_ACCESS,
      // PERMISSIONS.USER_ACCESS_ADD_USER,
      PERMISSIONS.USER_ACCESS_EDIT_OTHER_USER_ROLES,
      // PERMISSIONS.USER_ACCESS_ASSIGN_USERS_TO_SITES,
      PERMISSIONS.USER_ACCESS_VIEW_USERS_ASSIGNED_TO_SITE,
      // PERMISSIONS.USER_ACCESS_VIEW_ALL_USERS_IN_THE_NETWORK,
      // PERMISSIONS.USER_ACCESS_DELETE_USER
    ]
  },
  { 
    role: ROLES.LECTURESEULEMENT,
    permissions: [
      // PERMISSIONS.USER_ACCESS_ADMINISTRATOR_ACCESS,
      // PERMISSIONS.USER_ACCESS_ADD_USER,
      // PERMISSIONS.USER_ACCESS_EDIT_OTHER_USER_ROLES,
      // PERMISSIONS.USER_ACCESS_ASSIGN_USERS_TO_SITES,
      // PERMISSIONS.USER_ACCESS_VIEW_USERS_ASSIGNED_TO_SITE,
      // PERMISSIONS.USER_ACCESS_VIEW_ALL_USERS_IN_THE_NETWORK,
      // PERMISSIONS.USER_ACCESS_DELETE_USER
    ]
  },
];