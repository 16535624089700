import { SETISLOADINGLOCATIONS, SETLOCATIONS, SETISLOADINGLOCATIONSWITHDATA, SETLOCATIONSWITHDATA, SETISADDINGLOCATION, SETISEDITINGLOCATION, SETISREMOVINGLOCATION, SETISLOADINGDOORWAYS, SETDOORWAYS } from '../actions/locations';

const initialState = {
  isLoadingLocations: false,
  isLoadingLocationsWithData: false,
  locations: [],
  locationsWithData: [],
  isAddingLocation: false,
  isEditingLocation: false,
  isRemovingLocation: false,
  isLoadingDoorways: false,
  doorways: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGLOCATIONS:
      return {
        ...state,
        isLoadingLocations: action.isLoadingLocations
      }
    case SETISLOADINGLOCATIONSWITHDATA:
      return {
        ...state,
        isLoadingLocationsWithData: action.isLoadingLocationsWithData
      }
    case SETLOCATIONS:
      return {
        ...state,
        locations: action.locations
      }
    case SETLOCATIONSWITHDATA:
      return {
        ...state,
        locationsWithData: action.locationsWithData
      }
    case SETISADDINGLOCATION:
      return {
        ...state,
        isAddingLocation: action.isAddingLocation
      }
    case SETISEDITINGLOCATION:
      return {
        ...state,
        isEditingLocation: action.isEditingLocation
      }
    case SETISREMOVINGLOCATION:
      return {
        ...state,
        isRemovingLocation: action.isRemovingLocation
      }
    case SETISLOADINGDOORWAYS:
      return {
        ...state,
        isLoadingDoorways: action.isLoadingDoorways
      }
    case SETDOORWAYS:
      return {
        ...state,
        doorways: action.doorways
      }
    default:
      return state;
  }
}
